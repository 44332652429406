import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { TerminalsLayout } from 'modules/terminals/pages'
import { FacebookPixel, setupPixel } from 'core/utils'

let ReactPixel: FacebookPixel
type CentralesProps = PageProps
interface CentralesState {
  mounted: boolean
}
class Centrales extends Component<CentralesProps, CentralesState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
    ReactPixel = setupPixel()
    ReactPixel?.pageView()
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <TerminalsLayout />
        </AAnimate>
      </Layout>
    )
  }
}
export default Centrales
