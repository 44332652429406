import React, { Component, Fragment } from 'react'
// import { Container, Row, Col } from 'reactstrap'
import { TerminalsMap } from 'modules/terminals/components'
import { TerminalsLayoutProps } from './types'
import TerminalsList from 'modules/terminals/components/TerminalsList/TerminalsList'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'
import { Terminal } from 'modules/travel/models'
import InputGroup from 'reactstrap/lib/InputGroup'
import Input from 'reactstrap/lib/Input'
import InputGroupAddon from 'reactstrap/lib/InputGroupAddon'
import InputGroupText from 'reactstrap/lib/InputGroupText'
import './TerminalsLayout.css'
import Container from 'reactstrap/lib/Container'
import { TerminalService } from 'core/services/terminalServices/terminalsService'
import { Schedules } from 'modules/terminals/models/schedules'

export interface CropTermin {
  corp: Terminal[]
  search: String
}
export interface TerminalOrder {
  prefix: string
  order: number
  id: number
}
export class TerminalsLayout extends Component<TerminalsLayoutProps, CropTermin> {
  state = {
    corp: this.props.corp,
    search: ''
  }
  terminalOrderByidPrefix: TerminalOrder[] = [
    { id: 1, prefix: 'LMM01', order: 0 },
    { id: 8, prefix: 'LMM02', order: 1 },
    { id: 6, prefix: 'GVE01', order: 2 },
    { id: 5, prefix: 'CUL01', order: 3 },
    { id: 4, prefix: 'MZT01', order: 4 },
    { id: 13, prefix: 'UAC01', order: 6 },
    { id: 14, prefix: 'MXL', order: 7 },
    { id: 12, prefix: 'TEC01', order: 8 },
    { id: 11, prefix: 'TIJ', order: 9 },
    { id: 17, prefix: 'GDL01', order: 10 },
    { id: 21, prefix: 'CEN01', order: 11 },
    { id: 16, prefix: 'LMM2', order: 12 },
    { id: 2, prefix: 'CORP', order: 13 }
  ]
  Lista: Terminal[] = []
  constructor(props: TerminalsLayoutProps) {
    super(props)
    this.filterList = this.filterList.bind(this)
    this.Lista = []
  }

  componentDidMount() {
    const { getCorp } = this.props
    getCorp().then(item => {
      const filterItem = item.filter(t => t.name !== 'Guamuchil')
      this.setState({ corp: this.orderIdTerminals(filterItem) })
      this.Lista = this.orderIdTerminals(filterItem)
      TerminalService.getScheduleTerminal().then(data => {
        this.orderShedule(data)
      })
    })
  }

  orderIdTerminals(list: Terminal[]): Terminal[] {
    const array: Terminal[] = []
    this.terminalOrderByidPrefix.sort((a, b) => a.order - b.order)
    this.terminalOrderByidPrefix.map(t => {
      list.map(p => {
        if (t.id === p.id) {
          array.push(p)
        }
      })
    })

    return array
  }

  orderShedule(schedules: Schedules[]) {
    this.Lista.map(terminal => {
      schedules.forEach(shedule => {
        if (shedule.branchoffice_id === terminal.id) {
          const item: any[] = []
          item.push(shedule)
          if (terminal.schedules) {
            terminal.schedules.push(shedule)
          } else {
            terminal.schedules = item
          }
        }
      })
    })
    this.setState({ corp: this.Lista })
  }

  filterList(event: any) {
    this.setState({ search: event.target.value })
    let updatedList = this.state.corp
    this.state.search.replace(/[0-9`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    updatedList = updatedList.filter(item => {
      return item.name.toLowerCase().search(this.state.search.toLowerCase()) !== -1
    })
    this.setState({ corp: this.orderIdTerminals(updatedList) })
    if (
      this.state.search.toString() === '' ||
      this.state.search.toString() === ' ' ||
      this.state.search.toString().length === 1 ||
      this.state.search === null
    ) {
      this.setState({ corp: this.orderIdTerminals(this.Lista) })
    }
  }

  render() {
    const { corp } = this.state

    return (
      <Fragment>
        <Container fluid className="hero-rosa hero-rosa-terminal pad">
          <Row>
            <Col sm={12}>
              <h1 className="big-white">Nuestras terminales</h1>
            </Col>
          </Row>
        </Container>
        <hr />
        <Row className="RowSlideOverflow" />
        <hr />
        <Row className="RowSlideOverflow">
          <Col>
            <form>
              <InputGroup style={{ width: '97%' }}>
                <Input
                  type="text"
                  pattern="[A-Za-z0-9]+"
                  value={this.state.search}
                  onChange={this.filterList}
                  placeholder="Encuentra tu central"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText style={{ background: 'white' }}>
                    <img
                      style={{ height: '25px', width: '25px', background: 'white' }}
                      src="https://img.icons8.com/ios/50/000000/google-web-search.png"
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
          </Col>
        </Row>
        <hr />
        {corp.length === 0 ? (
          <Fragment>
            <Row>
              <Col md={12} sm={12} lg={12} style={{ textAlign: 'center' }}>
                <p>
                  No se encontraron resultados. Dudas?. Comunicate al <a href="tel:(668) 688-5636">Tel.(668)688-5636</a>
                </p>
              </Col>
            </Row>
          </Fragment>
        ) : null}
        {corp.length > 0 ? (
          <Fragment>
            <Row className="RowSlideOverflow">
              <Col md={12} xs={12} lg={5} sm={12} xl={4}>
                <TerminalsList
                  terminals={corp.map(t => ({
                    id: t.id,
                    name: t.name,
                    address: t.address,
                    phone: t.phone,
                    location: {
                      lat: parseFloat(t.latitude),
                      lng: parseFloat(t.longitude)
                    },
                    schedules: t.schedules
                  }))}
                />
              </Col>

              <Col className="MapMovil">
                <div style={{ height: 500 }}>
                  <TerminalsMap
                    terminals={this.state.corp.map(t => ({
                      id: t.id,
                      name: t.name,
                      address: t.address,
                      phone: t.phone,
                      location: {
                        lat: parseFloat(t.latitude),
                        lng: parseFloat(t.longitude)
                      }
                    }))}
                    height={600}
                  />
                </div>
              </Col>
            </Row>
          </Fragment>
        ) : null}
      </Fragment>
    )
  }
}

export default TerminalsLayout
