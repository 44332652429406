import React, { Component } from 'react'
import Container from 'reactstrap/lib/Container'
import Row from 'reactstrap/lib/Row'
import { UncontrolledCollapse, Button, CardBody, Card, Col } from 'reactstrap'
import { parsePhone, FacebookPixel, setupPixel } from 'core/utils'
import flecha from '../../../../img/mapaFlecha.png'
import llamar from '../../../../img/llamar.svg'
import llegar from '../../../../img/como-llegar.svg'
import './TerminalsList.css'
import { Schedules } from 'modules/terminals/models/schedules'

let ReactPixel: FacebookPixel
export interface CropTermin {
  terminals: { id: number; name: string; address: string; phone: string; location: { lat: number; lng: number }; schedules: Schedules[] }[]
}

interface SchedulesSpan {
  order: number
  name: string
  dayValue: boolean
}

export class TerminalsList extends Component<CropTermin, CropTermin> {
  constructor(props: CropTermin) {
    super(props)
    this.setState(props)
    this.terminalSelected = this.terminalSelected.bind(this)
  }

  componentDidMount(): void {
    ReactPixel = setupPixel()
  }

  terminalSelected(t: any) {
    ReactPixel?.track('FindLocation', {
      type: 'Terminal',
      name: t.name
    })
  }

  schedulesView(schedules: Schedules[]): string[] {
    const schedulesView: string[] = []
    schedules.forEach(sch => {
      const schedulesSpan: SchedulesSpan[] = [
        { order: 0, name: 'Lunes, ', dayValue: sch.mon },
        { order: 1, name: 'Martes, ', dayValue: sch.tue },
        { order: 2, name: 'Miercoles, ', dayValue: sch.wen },
        { order: 3, name: 'Jueves, ', dayValue: sch.thu },
        { order: 4, name: 'Viernes ', dayValue: sch.fri },
        { order: 5, name: 'Sabado, ', dayValue: sch.sat },
        { order: 6, name: 'Domingo ', dayValue: sch.sun }
      ]
      schedulesSpan.sort((a, b) => a.order - b.order)
      let view: string = ''
      view = ''
      schedulesSpan.forEach(schp => {
        if (schp.dayValue) {
          view = view + schp.name
        }
      })
      const viewFinal = ` ${view}  de ${sch.hour_start}  a  ${sch.hour_end} `
      schedulesView.push(viewFinal)
    })
    return schedulesView
  }
  render() {
    const { terminals } = this.props

    return (
      <Container className="ContainarList">
        {' '}
        {terminals.map((t: any) => {
          if (t !== null) {
            return (
              <div key={t.id}>
                <div className="btnList" id={`order${t.id}`} style={{ marginBottom: '1rem', border: 'none', height: '50px' }}>
                  <Row key={`id${t.id}`}>
                    <Col md={3} sm={10} xs={9} lg={3} style={{ textAlign: 'center', paddingTop: '10px' }}>
                      <h6 style={{ color: 'white', fontFamily: " 'Montserrat', sans-serif" }}>{t.name}</h6>
                    </Col>

                    <Col md={{ offset: 7 }} lg={{ offset: 7 }} xs={{ offset: 0 }} sm={{ offset: 10 }} />
                    <Col style={{ paddingRight: '20px' }}>
                      <img style={{ width: '25px', height: '25px' }} src={flecha} />
                    </Col>
                  </Row>
                </div>
                <UncontrolledCollapse toggler={`#order${t.id}`}>
                  <Card style={{}}>
                    <CardBody>
                      <Row>
                        <Col sm={'auto'} md={'auto'} />
                        <Col>
                          {t.phone != null ? (
                            <a target="_blank" href={`tel:${parsePhone(t.phone)}` || ''}>
                              {' '}
                              {t.phone ? <p className="big-grey">Tel. {parsePhone(t.phone)}</p> : null}
                            </a>
                          ) : (
                            ' '
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={'auto'} />
                        <Col>
                          <a
                            target="_blank"
                            onClick={() => this.terminalSelected(t)}
                            href={`https://www.google.com/maps/dir/Current+Location/${t.location.lat},${t.location.lng}`}
                          >
                            <p className="big-grey">{t.address || ''}</p>
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={'auto'} md={'auto'} />
                        <Col>
                          {t.name !== 'Corporativo' ? (
                            <div className="big-grey">
                              {t.schedules
                                ? this.schedulesView(t.schedules).map(schedules => {
                                    return (
                                      <p key={schedules} className="big-grey">
                                        <i className="far fa-clock" />
                                        {schedules}
                                      </p>
                                    )
                                  })
                                : ''}
                            </div>
                          ) : (
                            <div className="big-grey">
                              {t.schedules
                                ? this.schedulesView(t.schedules).map(schedules => {
                                    return (
                                      <p key={schedules} className="big-grey">
                                        <i className="far fa-clock" />
                                        {schedules}
                                      </p>
                                    )
                                  })
                                : ''}
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row md="2">
                        <Col>
                          {t.phone != null ? (
                            <Button
                              target="_blank"
                              href={`tel:${parsePhone(t.phone)}`}
                              outline
                              style={{ width: '150px', height: '45px', background: '#737277', borderRadius: '60px', border: 'none' }}
                            >
                              <Row>
                                <Col>
                                  <img style={{ width: '20px', height: '20px' }} src={llamar} /> {'    '}
                                  <label style={{ fontSize: '10px', color: 'white' }}> Llamar</label>
                                </Col>
                              </Row>
                            </Button>
                          ) : (
                            ''
                          )}
                        </Col>
                        <Col>
                          <Button
                            target="_blank"
                            onClick={() => this.terminalSelected(t)}
                            href={`https://www.google.com/maps/dir/Current+Location/${t.location.lat},${t.location.lng}`}
                            outline
                            style={{ width: '150px', height: '45px', borderRadius: '60px', background: '#d01d56', border: 'none' }}
                          >
                            <Row>
                              <Col>
                                <img style={{ width: '20px', height: '20px' }} src={llegar} />
                                {'    '}

                                <label style={{ fontSize: '10px', color: 'white' }}> Como llegar</label>
                              </Col>
                            </Row>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </UncontrolledCollapse>
              </div>
            )
          }
        })}
      </Container>
    )
  }
}

export default TerminalsList
